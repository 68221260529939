import { Fragment, useEffect, useState } from 'react'
import { EuiButton, EuiFieldText, EuiFormRow, EuiSelect, EuiSpacer } from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks'


export default function CreateBulkSalesCode(props) {

    const navigate = useNavigate();
    const [accountId, setAccountId] = useState('')
    const [brandId, setBrandId] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')
    const [{ data: accounts, loading: loadingAccounts }] = useAxios(
        {
            url: '/data/accounts',
            method: 'GET'
        }
    )
    const [{ data: accountSummary, loading: loadingAccountSummary }, refetchAccountSummary] = useAxios(
        {
            url: '/data/account-summary',
            method: 'GET',
            params: { accountId }
        },
        { manual: true }
    )
    const [{ loading: creatingBulkCode }, executeCreateBulkCode] = useAxios(
        {
            url: '/data/bulk-code',
            method: 'POST',
            data: { accountId, brandId, customerEmail }
        },
        { manual: true }
    )
    const onChangeAccount = (e) => {
        setAccountId(e.target.value);
        setBrandId('')
    };
    const onChangeBrand = (e) => {
        setBrandId(e.target.value);
    };
    useEffect(() => {
        console.log(`accountId: ${accountId}`)
        if (accountId && accountId !== '') refetchAccountSummary();
    }, [accountId, refetchAccountSummary])

    const createCode = async () => {
        try {
            await executeCreateBulkCode();
            navigate('../bulk-sales-codes')
        } catch (e) {
            console.error("Error creating code")
        }
    }

    return (
        <Fragment>
            <EuiFormRow label="Account">
                <EuiSelect
                    isLoading={loadingAccounts}
                    options={(accounts?.items || []).map(b => {
                        return { value: b.id, text: b.name }
                    })}
                    value={accountId}
                    hasNoInitialSelection={true}
                    onChange={(e) => onChangeAccount(e)}
                />
            </EuiFormRow>
            <EuiFormRow label="Brand">
                <EuiSelect
                    isLoading={loadingAccountSummary}
                    disabled={!accountId || accountId === ''}
                    options={(accountSummary?.brands || []).map(b => {
                        return { value: b.brandId, text: b.brandName }
                    })}
                    value={brandId}
                    hasNoInitialSelection={true}
                    onChange={(e) => onChangeBrand(e)}
                />
            </EuiFormRow>
            <EuiFormRow label="Customer Email">
                <EuiFieldText disabled={!brandId || brandId === ''} value={customerEmail} name="customerEmail" onChange={(e) => setCustomerEmail(e.target.value)} />
            </EuiFormRow>
            <EuiSpacer />
            <EuiButton size="s" isLoading={creatingBulkCode} disabled={!brandId || !customerEmail} fill onClick={() => createCode()}>Create Code</EuiButton>
        </Fragment>
    );
}