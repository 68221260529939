import { Fragment, useState, useEffect } from 'react'
import {
    EuiForm, EuiComboBox,
    EuiFormRow, EuiTitle,
    EuiFlexGroup, EuiFlexItem, EuiSpacer
} from '@elastic/eui';
import useAxios from 'axios-hooks'
import { Chart, AreaSeries, Heatmap, Axis, Position, ScaleType, CurveType, BarSeries } from '@elastic/charts';

export default function Dashboard() {

    const [{ data, loading }] = useAxios(
        {
            url: '/data/accounts',
            method: 'GET'
        }
    )
    const [{ data: stats }, getStats] = useAxios(
        {
            url: '/data/stats',
            method: 'GET'
        },
        { manual: true }
    )

    const [selectedAccounts, setSelectedAccounts] = useState([]);

    // Check for changes
    useEffect(() => {
        if (selectedAccounts && selectedAccounts.length === 1) {
            getStats({ params: { accountId: selectedAccounts[0].value } })
        }
    }, [getStats, selectedAccounts]);

    return (
        <Fragment>
            <EuiTitle size="m">
                <h1>Accounts</h1>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiForm>
                        <EuiFormRow label="Brands">
                            <EuiComboBox isLoading={loading}
                                singleSelection={{ asPlainText: true }}
                                placeholder="Select Account"
                                options={(data?.items || []).map(b => { return { value: b.id, label: b.name } }) || []}
                                onChange={(selectedOptions) => { setSelectedAccounts(selectedOptions) }}
                                selectedOptions={selectedAccounts}
                            />
                        </EuiFormRow>
                    </EuiForm>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {stats &&
                <Fragment>
                    <Chart title='Account HandleIts Per Week' size={['100%', 300]}>
                        <Axis id="bottom" position={Position.Bottom} />
                        <Axis id="right" position={Position.Right} tickFormat={(d) => `${Number(d).toFixed(0)}`}
                        />
                        <Axis id="left" groupId='bar' position={Position.Left} tickFormat={(d) => `${Number(d).toFixed(0)}`}
                        />
                        <BarSeries
                            id="Weekly HandleIts"
                            groupId='bar'
                            xScaleType={ScaleType.Ordinal}
                            yScaleType={ScaleType.Linear}
                            xAccessor={'week'}
                            yAccessors={['weekCount']}
                            yNice
                            data={(stats?.accountWeekStats || [])}
                            curve={CurveType.CURVE_MONOTONE_X}
                        />
                        <AreaSeries
                            id="Total HandleIts"
                            xScaleType={ScaleType.Ordinal}
                            yScaleType={ScaleType.Linear}
                            xAccessor={'week'}
                            yAccessors={['totalCount']}
                            yNice
                            data={(stats?.accountWeekStats || [])}
                            curve={CurveType.CURVE_MONOTONE_X}
                        />
                    </Chart>
                    <EuiSpacer />
                    <Chart title='Brand HandleIts Per Month' size={['100%', new Set([...(stats?.brandMonthStats || []).map(s => s.brandName)]).size * 50 + 75]}>
                        <Heatmap
                            xSortPredicate="alphaAsc"
                            ySortPredicate='alphaAsc'
                            xAccessor={'month'}
                            yAccessor={'brandName'}
                            valueAccessor={'handleitCount'}
                            valueFormatter={(value) => value.toFixed(0)}
                            data={(stats?.brandMonthStats || [])}
                            colorScale={{
                                type: 'bands',
                                bands: [
                                    { start: -Infinity, end: 39, color: '#f7eaea' },
                                    { start: 40, end: Infinity, color: '#c0e2d7' },
                                ]
                            }}
                        />
                    </Chart>
                    <EuiSpacer />
                    <Chart title='Editor HandleIts Per Month' size={['100%', new Set([...(stats?.userMonthStats || []).map(s => s.email)]).size * 50 + 75]}>
                        <Heatmap
                            xSortPredicate="alphaAsc"
                            ySortPredicate='alphaAsc'
                            xAccessor={'month'}
                            yAccessor={'email'}
                            valueAccessor={'handleitCount'}
                            valueFormatter={(value) => value.toFixed(0)}
                            data={(stats?.userMonthStats || [])}
                            colorScale={{
                                type: 'bands',
                                bands: [
                                    { start: -Infinity, end: 10, color: '#f7eaea' },
                                    { start: 11, end: Infinity, color: '#c0e2d7' },
                                ]
                            }}
                        />
                    </Chart>
                </Fragment>
            }
        </Fragment>
    );
}