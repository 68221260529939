import { Fragment, useEffect, useState } from 'react';
import {
    EuiForm,
    EuiDescribedFormGroup,
    EuiFormRow,
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiBottomBar
} from '@elastic/eui';
import useAxios from 'axios-hooks';

export default function NurtureSettings(props) {
    const optionsExcludedRoles = (props?.nurtureSettings?.excludedRoles || []).map(i => {
        if (typeof i === 'string') return { label: i }
        return i;
    })

    const [hasChanges, setHasChanges] = useState(false);

    const [excludedRoles, setExcludedRoles] = useState(optionsExcludedRoles);
    const onCreateRole = (searchValue) => {
        const newOption = {
            label: searchValue,
        };
        // Select the option.
        setExcludedRoles([...excludedRoles, newOption]);
    };
    const onRolesChange = (selectedOptions) => {
        setExcludedRoles(selectedOptions);
    };

    // Check for changes
    useEffect(() => {
        setHasChanges(
            excludedRoles.map(i => i.label).toString() !== (props?.nurtureSettings?.excludedRoles || []).toString()
        );
    }, [excludedRoles, props?.nurtureSettings?.excludedRoles]);

    // Save updates
    const [
        { loading }, executeUpdate
    ] = useAxios({
        url: '/data/platform-settings',
        method: 'POST'
    }, {
        manual: true
    })

    async function updateSettings() {
        await executeUpdate({
            data: {
                "settingName": "nurture",
                "settingValue": {
                    "excludedRoles": excludedRoles.map(i => i.label)
                }
            }
        })
        props.refetch()
    }

    return (
        <Fragment>
            <EuiForm component="form" disabled={loading}>
                <EuiDescribedFormGroup
                    ratio="third"
                    title={<h2>Inbound Email Settings</h2>}
                    description={
                        <Fragment>
                            Settings to control inbound HandleIt and Nurture emails
                        </Fragment>
                    }
                >
                    <EuiFormRow fullWidth
                        label="Excluded Roles" helpText="Roles to exclude (if opted in by brand) from HandleIt and Nurture process">
                        <EuiComboBox fullWidth
                            noSuggestions
                            placeholder="Add a role"
                            selectedOptions={excludedRoles}
                            onCreateOption={onCreateRole}
                            onChange={onRolesChange}
                        >
                        </EuiComboBox>
                    </EuiFormRow>
                </EuiDescribedFormGroup>
            </EuiForm>
            {hasChanges && <EuiBottomBar>
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                        <EuiButton disabled={loading} onClick={() => props.refetch()} color="ghost" size="s" iconType="save">
                            Discard Changes
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton isLoading={loading} color="primary" fill size="s" iconType="save"
                            onClick={() => { updateSettings() }}>
                            Save Changes
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiBottomBar>}
        </Fragment>
    );
}