import React, { useState, useCallback, Fragment } from 'react';
import {
    EuiButton, EuiComboBox, EuiDatePicker, EuiFieldText, EuiFlexGroup, EuiFlexItem,
    EuiForm, EuiFormRow, EuiSpacer, EuiTextArea
} from "@elastic/eui";
import ReactQuill from 'react-quill';
import useAxios from "axios-hooks";
import { useUser } from '@clerk/clerk-react';

const SEARCH_URI = 'https://autocomplete.clearbit.com/v1/companies/suggest?query=';

export default function PressRelease(props) {
    const userApi = useUser();
    const [selectedOptions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [company, setCompany] = useState(null);
    const [prContactName, setPrContactName] = useState('');
    const [prContactEmail, setPrContactEmail] = useState('');
    const [prContactPhone, setPrContactPhone] = useState('');
    const [prTitle, setPrTitle] = useState('');
    const [prBody, setPrBody] = useState('');
    const [prAbout, setPrAbout] = useState('');
    const [prPostDate, setPrPostDate] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);

    const [
        { loading: postingPR },
        executeSendPromotions
    ] = useAxios(
        {
            url: '/data/prs',
            method: 'POST'
        },
        { manual: true }
    )

    const isValid = () => {
        return selectedBrands.length > 0 && !!company && !!prContactName && !!prContactEmail && !!prContactPhone && !!prPostDate && !!prTitle
    }

    const onPostPR = function () {
        const data = {
            accountId: props.account.accountId,
            brands: selectedBrands.map(b => b.value),
            company,
            prTitle,
            prBody,
            prAbout,
            prPostDate,
            prContact: {
                name: prContactName,
                email: prContactEmail,
                phone: prContactPhone
            },
            customer: {
                email: userApi.user.primaryEmailAddress.emailAddress
            }
        };
        executeSendPromotions({ data })
            .then(() => {
                if (props.addToast) props.addToast({ title: "Press Release Posted", color: 'success' })
                if (props.onReset) props.onReset();
            })
            .catch((e) => {
                if (props.addToast) props.addToast({ title: "Error Posting Press Release", color: 'danger' })
            })
            .finally(() => {
            });
    }

    const onChange = (selectedOptions) => {
        setCompany(selectedOptions[0]);
    };

    const onSearchChange = useCallback((query) => {
        if (query != null) {
            setLoading(true);

            fetch(`${SEARCH_URI}${query}`)
                .then((resp) => resp.json())
                .then((items) => {
                    const options = items.map((i) => ({
                        logo: i.logo,
                        id: i.domain,
                        domain: i.domain,
                        name: i.name,
                        label: i.name,
                    }));
                    setLoading(false);
                    setOptions(options);
                });
        }

    }, []);

    const renderOption = (option) => {
        return (
            <Fragment>
                <img
                    alt={option.label}
                    src={option.logo}
                    style={{
                        height: '24px',
                        marginRight: '10px',
                        width: '24px',
                    }}
                />
                <span>{option.label} - {option.domain}</span>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <EuiSpacer />
            <EuiButton isLoading={postingPR} disabled={!isValid()} fill onClick={() => { onPostPR() }}>Post PR</EuiButton>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiForm>
                        <EuiFormRow label="Brands">
                            <EuiComboBox
                                placeholder="Select brands"
                                options={props?.account?.brands?.map(b => { return { value: b.brandId, label: b.brandName } }) || []}
                                onChange={(selectedOptions) => { setSelectedBrands(selectedOptions) }}
                                selectedOptions={selectedBrands}
                            />
                        </EuiFormRow>
                        <EuiFormRow>
                            <EuiComboBox
                                aria-label="Accessible screen reader label"
                                placeholder="Search companies"
                                async
                                options={options}
                                selectedOptions={selectedOptions}
                                isLoading={isLoading}
                                onChange={onChange}
                                onSearchChange={onSearchChange}
                                renderOption={renderOption}
                                singleSelection={{ asPlainText: true }}
                            />
                        </EuiFormRow>
                        <EuiSpacer />
                        {company && <Fragment>
                            <img
                                alt={company.name}
                                src={company.logo}
                                style={{
                                    maxHeight: '64px',
                                    marginRight: '10px',
                                    maxWidth: '64px',
                                }}
                            />
                            <span>{company.name} - {company.domain}</span>
                        </Fragment>}
                        <EuiSpacer />
                        <EuiFormRow label="PR Contact Name" isInvalid={!prContactName}>
                            <EuiFieldText min={0} isInvalid={!prContactName}
                                value={prContactName} onChange={(e) => setPrContactName(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow label="PR Contact Email" isInvalid={!prContactEmail}>
                            <EuiFieldText min={0} isInvalid={!prContactEmail}
                                value={prContactEmail} onChange={(e) => setPrContactEmail(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow label="PR Contact Phone" isInvalid={!prContactPhone}>
                            <EuiFieldText min={0} isInvalid={!prContactPhone}
                                value={prContactPhone} onChange={(e) => setPrContactPhone(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow label="PR Post Date" isInvalid={!prPostDate}>
                            <EuiDatePicker showTimeSelect isInvalid={!prPostDate} selected={prPostDate} onChange={(d) => setPrPostDate(d)} />
                        </EuiFormRow>
                    </EuiForm>

                </EuiFlexItem>
                <EuiFlexItem>
                    <h5>Press Release Title</h5>
                    <EuiFormRow fullWidth isInvalid={!prTitle} >
                        <EuiTextArea fullWidth isInvalid={!prTitle} style={{ height: "125px" }} resize="none"
                            value={prTitle} onChange={(e) => setPrTitle(e.target.value)}
                        />
                    </EuiFormRow>
                    <EuiSpacer />
                    <h5>Press Release Body</h5>
                    <ReactQuill tabIndex={2} theme="snow" value={prBody} onChange={setPrBody}>
                        <div id="a" style={{ height: "500px" }} />
                    </ReactQuill>
                    <EuiSpacer />
                    <h5>Press Release About</h5>
                    <ReactQuill tabIndex={3} theme="snow" value={prAbout} onChange={setPrAbout} >
                        <div id="b" style={{ height: "250px" }} />
                    </ReactQuill>

                </EuiFlexItem>
            </EuiFlexGroup>
        </Fragment>
    )
}