import { EuiHeader, EuiHeaderLink, EuiHeaderSection, EuiHeaderSectionItem, EuiImage, EuiPanel } from '@elastic/eui';
import { Fragment } from 'react';
import { Route, Routes, Link, Navigate } from "react-router-dom";
import Accounts from './Accounts';
import logo from './Assets/logo-swiftrev-small-white.png';
import { UserButton, SignedIn, SignedOut, RedirectToSignIn, useUser } from '@clerk/clerk-react';
import Forbidden from './Forbidden';
import AccountContainer from './AccountContainer';
import PlatformSettings from './PlatformSettings';
import Leads from './Leads';
import Companies from './Companies';
import BulkSalesCodes from './BulkSalesCodes';
import CreateBulkSalesCode from './CreateBulkSalesCode';
import Dashboard from './Dashboard';

export default function Container() {
  const userApi = useUser();
  return (
    <Fragment>
      <EuiHeader theme='dark'>
        <EuiHeaderSection grow={true}>
          <EuiHeaderSectionItem border="none">
            <Link to="/accounts" ><EuiImage style={{ "marginLeft": "16px", "height": "24px" }} alt="SwiftRev" url={logo} /></Link>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderLink href='/dashboard'> Dashboard</EuiHeaderLink>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderLink href='/accounts'> Accounts</EuiHeaderLink>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderLink href='/leads'> Leads</EuiHeaderLink>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderLink href='/companies'> Companies</EuiHeaderLink>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderLink href='/bulk-sales-codes'> Bulk Sales</EuiHeaderLink>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderLink href='/settings'> Settings</EuiHeaderLink>
        </EuiHeaderSection>
        <EuiHeaderSection grow={false} side="right" style={{ "marginRight": "16px" }}>
          <EuiHeaderSectionItem border='none'>
            <UserButton />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
      <Routes>
        <Route path='dashboard/*' element={<EuiPanel><Dashboard /></EuiPanel>} />
        <Route path="accounts/*" element={
          <Fragment>
            {
              userApi.user?.publicMetadata?.platformAdmin === "yes" &&
              <Routes>
                <Route index element={<Accounts />} />
                <Route path=":accountId/*" element={<AccountContainer />} />
              </Routes>
            }
            {userApi.user?.publicMetadata?.platformAdmin !== "yes" && <Navigate to="/forbidden" />}
          </Fragment>
        }>
        </Route>
        <Route path='leads/*' element={<EuiPanel><Leads /></EuiPanel>} />
        <Route path='companies/*' element={<EuiPanel><Companies /></EuiPanel>} />
        <Route path='bulk-sales-codes/*' element={<EuiPanel><BulkSalesCodes /></EuiPanel>} />
        <Route path='create-bulk-sales-code/*' element={<EuiPanel><CreateBulkSalesCode /></EuiPanel>} />
        <Route path='settings/*' element={<EuiPanel><PlatformSettings /></EuiPanel>} />
        <Route path='forbidden' element={<Forbidden />} />
        <Route path="*" element={
          <Fragment>
            <SignedIn>
              <Navigate to="dashboard" />
            </SignedIn>
            <SignedOut><RedirectToSignIn /></SignedOut>
          </Fragment>
        } />
      </Routes>
    </Fragment>
  );
}