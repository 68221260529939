import { Fragment, useState } from 'react'
import {
    htmlIdGenerator, EuiLink, EuiPanel, EuiButton, EuiGlobalToastList, EuiFieldText,
    EuiFormRow, EuiTitle, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiBasicTable, EuiText, EuiSwitch
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks'

const initialState = { name: '', email: '' }
const genToastId = htmlIdGenerator('toast');

export default function Accounts() {
    const navigate = useNavigate();
    const [formState, setFormState] = useState(initialState)
    const [toasts, setToasts] = useState([]);

    const [{ data, loading, error }, refetchAccounts] = useAxios(
        {
            url: '/data/accounts',
            method: 'GET'
        }
    )

    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };

    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    function setInput(key, value) {
        setFormState({ ...formState, [key]: value })
    }

    const [
        { loading: creatingAccount },
        executeCreateAccount
    ] = useAxios(
        {
            url: '/data/accounts',
            method: 'POST'
        },
        { manual: true }
    )

    function onAddAccountClick() {
        executeCreateAccount({
            data: { ...formState }
        })
            .then(() => {
                addToast({ title: "Account created!", color: 'success' })
                refetchAccounts();
            })
            .catch((e) => {
                addToast({ title: "Error creating account", color: 'error' })
            })
            .finally(() => {
                setFormState(initialState)
            })
    }

    const [
        { loading: updatingAccount },
        executeUpdateAccountStatus
    ] = useAxios(
        {
            url: '/data/account-status',
            method: 'POST'
        },
        { manual: true }
    )

    function updateAccountStatus(accountId, value) {
        executeUpdateAccountStatus({
            data: {
                accountId: accountId,
                status: value === true ? 'active' : 'inactive'
            }
        })
            .then(() => {
                addToast({ title: "Account updated!", color: 'success' })
                refetchAccounts()
            })
            .catch((e) => {
                addToast({ title: "Error updating account!", color: 'danger' })
            })
    }

    const columns = [{
        field: 'name',
        name: 'Account Name',
        render: (name, account) => {
            return <EuiLink onClick={() => { navigate(account.id) }} >{name}</EuiLink>
        },
    }, {
        field: 'name',
        name: 'Customer App',
        render: (name, account) => {
            return <EuiLink href={`${process.env.REACT_APP_SWIFTREV_APP_URL}/accounts/${account.id}`} target='_blank' >{name}</EuiLink>
        },
    }, {
        field: 'id',
        name: 'Account Id'
    },
    {
        field: 'status',
        name: 'Active',
        width: "100px",
        align: "center",
        render: (status, account) => {
            return <EuiSwitch onChange={(e) => { updateAccountStatus(account.id, e.target.checked) }} checked={status === 'active'} showLabel={false} label="Active"></EuiSwitch>
        }
    }]

    return (
        <Fragment>
            <EuiPanel>
                <EuiGlobalToastList
                    toasts={toasts}
                    dismissToast={removeToast}
                    toastLifeTimeMs={3000}
                />
                <EuiTitle size="m">
                    <h1>Accounts</h1>
                </EuiTitle>
                <EuiSpacer />
                <EuiBasicTable
                    loading={loading}
                    items={data?.items || []}
                    columns={columns}
                    noItemsMessage="There are no accounts available"
                    error={error != null ? 'Error loading accounts' : null}
                >
                </EuiBasicTable>
                <EuiSpacer />
                <EuiText size="m">
                    <h3>Add Account</h3>
                </EuiText>
                <EuiFlexGroup style={{ maxWidth: 600 }}>
                    <EuiFlexItem>
                        <EuiFormRow isDisabled={creatingAccount}>
                            <EuiFieldText autoComplete="false"
                                placeholder="Enter new account name"
                                onChange={event => setInput('name', event.target.value)}
                                value={formState.name}
                                aria-label="New account name"
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFormRow>
                            <EuiButton isLoading={creatingAccount} disabled={creatingAccount} fill onClick={() => onAddAccountClick()}>Add Account</EuiButton>
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
        </Fragment>
    );
}