import {
    EuiPage, EuiPageBody,
    EuiPanel,
    EuiTabbedContent
} from '@elastic/eui';
import { Fragment, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import { useUser } from '@clerk/clerk-react';
import Loading from './Loading';
import Promote from './Promote';
import PressReleaseContainer from './PressReleaseContainer';
import Brands from './Brands';
export default function AccountContainer() {
    const nav = useNavigate();
    const params = useParams();
    const userApi = useUser();

    const [{ data: account, loading: accountLoading, error: accountError }, refetchAccount] = useAxios({
        method: 'get',
        url: '/data/account-summary',
        params: {
            accountId: params.accountId
        },
    });

    useEffect(() => {
        // Check to make sure this user is on this account
        if (userApi.user?.publicMetadata?.account_id && userApi.user?.publicMetadata?.account_id !== params.accountId) {
            nav(`../${userApi.user?.publicMetadata?.account_id}`)
        }
    }, [nav, params.accountId, userApi.user?.publicMetadata?.account_id]);

    const tabs = [
        {
            id: "promote",
            name: "Handle It",
            content: (
                <Fragment>
                    <Promote account={account} />
                </Fragment>
            )
        },
        {
            id: "pr",
            name: "Create PR",
            content: (
                <Fragment>
                    <PressReleaseContainer account={account} />
                </Fragment>
            )
        },
        {
            id: "brands",
            name: "Brands",
            content: (
                <Fragment>
                    <Brands account={account} refetchAccount={refetchAccount} />
                </Fragment>
            )
        }
    ]
    return (
        <Fragment>
            {account && !accountLoading && !accountError &&
                <EuiPage paddingSize="none" className='full-height'>
                    <EuiPageBody >
                        <EuiPanel paddingSize="l" grow={true} style={{ paddingBottom: "75px" }}>
                            <EuiTabbedContent
                                tabs={tabs}
                                initialSelectedTab={tabs[0]}
                                autoFocus="selected"
                            />
                        </EuiPanel>
                    </EuiPageBody>
                </EuiPage>
            }
            {accountLoading && <Loading message="Loading Account ..." />}
        </Fragment>
    );
}