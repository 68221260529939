import { Fragment } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import NurtureSettings from "./NurtureSettings";
import useAxios from 'axios-hooks'
import { EuiText } from '@elastic/eui';
export default function PlatformSettings() {

    const [{ data: nurtureSettings, loading: loadingNurtureSettings }, refetchNurtureSettings] = useAxios(
        {
            url: '/data/platform-settings?name=nurture',
            method: 'GET'
        }
    )
    return (
        <Fragment>
            <Routes>
                <Route path="nurture/*" element={
                    (
                        <Fragment>
                            {loadingNurtureSettings && <EuiText>Loading Settings...</EuiText>}
                            {!loadingNurtureSettings && <NurtureSettings nurtureSettings={nurtureSettings} refetch={refetchNurtureSettings} />}
                        </Fragment>
                    )
                } />
                <Route path="*" element={<Navigate to="nurture" />} />
            </Routes>
        </Fragment>
    )
}