import React from 'react'; import {
    EuiEmptyPrompt,
} from '@elastic/eui';

export default function Forbidden() {
    return (
        <EuiEmptyPrompt
            iconType="alert" iconColor='danger'
            color="subdued"
            title={<h2>Forbidden</h2>}
            layout="vertical"
            body={
                <p>
                    Sorry, you are not authorized to view this page.
                </p>
            }
        />
    );
}