import React from 'react'; import {
    EuiEmptyPrompt,
    EuiImage,
} from '@elastic/eui';
import logo from './Assets/logo-swiftrev-small-black.png';

export default function Loading(props) {
    return (
        <EuiEmptyPrompt
            icon={<EuiImage size={200} alt="SwiftRev" url={logo} />}
            titleSize="xs"
            title={<p>{props.message}</p>}
            layout="vertical"
            actions={[]}
        />
    );
}