import { Fragment } from 'react'
import {
    EuiLink, EuiButtonEmpty, EuiTitle, EuiSpacer,
    EuiBasicTable
} from '@elastic/eui';
import useAxios from 'axios-hooks'

export default function PRFirmCompanies(props) {
    const [{ data, loading, error }, refetchCompanies] = useAxios(
        {
            url: '/data/companies',
            method: 'GET',
            params: { "companyType": "prFirm" }
        }
    )

    // Save updates
    const [
        { loading: updatingCompany }, executeUpdate
    ] = useAxios({
        url: '/data/company-type',
        method: 'POST'
    }, {
        manual: true
    })

    function updateCompany(domain, companyType) {
        executeUpdate({
            data: {
                "companyType": companyType,
                "domain": domain
            }
        })
            .then(() => {
                props.addToast({ title: "Company Updated", color: 'success' })
            })
            .catch((e) => {
                props.addToast({ title: "Error updating company", color: 'danger' })
            })
            .finally(() => {
                refetchCompanies()
            })
    }

    const columns = [{
        field: 'domain',
        name: 'Company Domain',
        dataType: 'string',
        width: "400px",
        render: (domain) => {
            return <EuiLink href={`https://${domain}`} target='_blank' >{domain}</EuiLink>
        },
    }, {
        field: 'companyType',
        name: 'Select Company Type',
        dataType: 'string',
        render: (companyType, company) => {
            return <Fragment>
                <EuiButtonEmpty onClick={() => { updateCompany(company.domain, 'corporateMarketer') }} flush="left" type='link'>Corporate Marketer</EuiButtonEmpty>
                <EuiButtonEmpty onClick={() => { updateCompany(company.domain, 'excluded') }} flush="left" type='link'>Exclude</EuiButtonEmpty>
            </Fragment>
        },
    }]

    return (
        <Fragment>
            <EuiBasicTable
                loading={loading || updatingCompany}
                items={data?.companies || []}
                columns={columns}
                noItemsMessage="There are no uncategorized companies available"
                error={error != null ? 'Error loading companies' : null}
            >
            </EuiBasicTable>
        </Fragment>
    );
}