import './App.css';
import '@elastic/charts/dist/theme_only_light.css';
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import Container from './Container'
import Loading from './Loading';
import NotFound from './NotFound';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn, ClerkLoaded, ClerkLoading } from "@clerk/clerk-react";
import { Fragment } from 'react';
import 'react-quill/dist/quill.snow.css';

function App() {
  const nav = useNavigate();
  return (
    <ClerkProvider frontendApi={process.env.REACT_APP_CLERK_FRONTEND_API} navigate={(to) => nav(to)} >
      <ClerkLoaded>
        <Routes>
          <Route path="/*" element={
            <Fragment>
              <SignedIn>
                <Container />
              </SignedIn>
              <SignedOut><RedirectToSignIn /></SignedOut>
            </Fragment>
          }>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ClerkLoaded>
      <ClerkLoading>
        <Loading message="Authenticating ..." />
      </ClerkLoading>
    </ClerkProvider>
  );
}

export default App;
