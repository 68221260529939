import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiProvider } from '@elastic/eui';
import { configure } from 'axios-hooks'
import Axios from 'axios'
const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL
})
axios.interceptors.request.use(
  async (config) => {

    const token = await window.Clerk.session.getToken({
      leewayInSeconds: 300,
      template: 'backend'
    });

    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);
configure({ axios, cache: false })
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <EuiProvider colorMode="light">
    <BrowserRouter><App /></BrowserRouter>
  </EuiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
