import { Fragment, useState } from 'react'
import { htmlIdGenerator, EuiFlexGroup, EuiGlobalToastList, EuiFlexItem, EuiButtonEmpty, useEuiTheme } from '@elastic/eui';
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import TBDCompanies from './TBDCompanies';
import ExcludedCompanies from './ExcludedCompanies';
import PRFirmCompanies from './PRFirmCompanies';
import CorporateMarketerCompanies from './CorporateMarketerCompanies';

const genToastId = htmlIdGenerator('toast');

export default function Companies() {
    const [toasts, setToasts] = useState([]);

    let euiTheme = useEuiTheme().euiTheme;
    let navigate = useNavigate();
    let location = useLocation();

    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };

    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    // Custom tab like button styles
    let unSelectedTabStyle = {
        "color": euiTheme.colors.text
    }
    let selectedTabStyle = {
        "backgroundColor": euiTheme.colors.lightestShade,
        "color": euiTheme.colors.link
    }

    const pathPrefix = `/companies/`

    return (
        <Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <EuiFlexGroup responsive={false} wrap gutterSize="s" alignItems="center">
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        style={location.pathname.startsWith(pathPrefix + 'uncategorized') ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate('uncategorized') }}>
                        Uncategorized
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        style={location.pathname.startsWith(pathPrefix + 'pr-firms') ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate('pr-firms') }}>
                        PR Firms
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        style={location.pathname.startsWith(pathPrefix + 'corporate-marketers') ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate('corporate-marketers') }}>
                        Corporate Marketers
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        style={location.pathname.startsWith(pathPrefix + 'excluded') ? selectedTabStyle : unSelectedTabStyle}
                        onClick={() => { navigate('excluded') }}>
                        Excluded
                    </EuiButtonEmpty>
                </EuiFlexItem>
            </EuiFlexGroup>
            <Routes>
                <Route index element={<Navigate to="uncategorized" replace={true} />} />
                <Route path="uncategorized" element={
                    <TBDCompanies addToast={addToast} removeToast={removeToast} />
                } />
                <Route path="pr-firms" element={
                    <PRFirmCompanies addToast={addToast} removeToast={removeToast} />
                } />
                <Route path="corporate-marketers" element={
                    <CorporateMarketerCompanies addToast={addToast} removeToast={removeToast} />
                } />
                <Route path="excluded" element={
                    <ExcludedCompanies addToast={addToast} removeToast={removeToast} />
                } />
            </Routes>


        </Fragment>
    );
}