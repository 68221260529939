import { Fragment, useState, useRef } from 'react';
import {
  EuiBasicTable,
  EuiSpacer,
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
  EuiGlobalToastList,
  EuiLink,
  EuiText,
  formatDate,
  EuiButtonIcon,
  copyToClipboard,
  EuiToolTip
} from '@elastic/eui';
import useAxios from 'axios-hooks';
import { useNavigate } from "react-router-dom";

export default function BulkSalesCodes(props) {
  const navigate = useNavigate();

  const [isModalVisible] = useState(false);


  const [{ data: bulkCodesData }] = useAxios(
    {
      url: '/data/bulk-code',
      method: 'GET'
    }
  )

  const [
    { loading: creatingBulkCode }
  ] = useAxios(
    {
      url: '/data/bulk-code',
      method: 'POST'
    },
    { manual: true }
  )

  function createCode() {
    navigate('../create-bulk-sales-code')
  }

  const [toasts, setToasts] = useState([]);
  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };


  const buttonRef = useRef();
  const [isTextCopied, setTextCopied] = useState(false);
  const onClick = (text) => {
    copyToClipboard(text);
    setTextCopied(true);
  };
  const onBlur = () => {
    setTextCopied(false);
  };

  const userColumns = [
    {
      field: 'sk',
      name: 'Code',
      dataType: 'string',
      width: "350px",
      render: (sk) => {
        return (
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiToolTip
                content={isTextCopied ? 'Code copied to clipboard' : 'Copy Code'}
              >
                <EuiButtonIcon
                  buttonRef={buttonRef}
                  aria-label="Copy text to clipboard"
                  color="text"
                  iconType="copy"
                  onClick={() => onClick(sk)}
                  onBlur={onBlur}
                />
              </EuiToolTip>
            </EuiFlexItem>
            <EuiFlexItem>{sk}</EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'accountId',
      name: 'Brand',
      dataType: 'string',
      width: "300px",
      render: (accountId, discountCode) => {
        return (
          <EuiText>{`${discountCode.accountId} | ${discountCode.brandId}`}</EuiText>
        )
      }
    },
    {
      field: 'customerEmail',
      name: 'Customer',
      dataType: 'string',
      width: "300px"
    },
    {
      field: 'isk',
      name: 'Status',
      dataType: 'string',
      width: "100px"
    },
    {
      field: 'prSlug',
      name: 'Press Release',
      dataType: 'string',
      width: "125px",
      render: (prSlug, discountCode) => {
        if (discountCode.isk === 'used') {
          return <EuiLink onClick={() => {
            navigate('../../../press-releases/' + props.brand?.brandId + '/' + prSlug)
          }} >View</EuiLink>
        }
        else return 'n/a'
      }
    },
    {
      field: 'createdAt',
      name: 'Created Date',
      dataType: 'string',
      width: "125px",
      render: (date) => formatDate(date, 'MM/DD/YYYY')
    }
  ]

  return (
    <Fragment>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton size="s" isLoading={creatingBulkCode} disabled={isModalVisible} fill onClick={() => createCode()}>Create Code</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiBasicTable
        tableCaption="Bulk Codes"
        items={bulkCodesData?.bulkCodes || []}
        rowHeader="createdAt"
        columns={userColumns}
        tableLayout="auto"
      />
    </Fragment>
  );
}