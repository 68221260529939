import React, { Fragment, useState } from 'react';
import { EuiGlobalToastList, htmlIdGenerator } from "@elastic/eui";
import PressRelease from './PressRelease';


export default function PressReleaseContainer(props) {
    const [prKey, setPrKey] = useState(htmlIdGenerator('pr'));

    const onReset = () => { setPrKey(htmlIdGenerator('pr')) }

    const [toasts, setToasts] = useState([]);
    const genToastId = htmlIdGenerator('toast');
    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    return (
        <Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            <PressRelease key={prKey} addToast={addToast} onReset={onReset} account={props.account} />
        </Fragment>
    )
}