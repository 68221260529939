import { Fragment, useState } from 'react'
import {
    EuiPanel, EuiButton, EuiGlobalToastList, EuiTitle, EuiSpacer,
    EuiBasicTable
} from '@elastic/eui';
import useAxios from 'axios-hooks'
import fileDownload from 'js-file-download'
import { DateTime } from 'luxon';

export default function Leads() {
    const [toasts, setToasts] = useState([]);

    const [{ data, loading, error }] = useAxios(
        {
            url: '/data/prfirm-leads',
            method: 'GET'
        }
    )

    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    // Download leads
    const [{ loading: downloadingLeads }, execDownloadLeads] = useAxios(
        {
            url: '/data/download-prfirm-leads',
            method: 'GET'
        },
        { manual: true }
    );
    const downLoadLeads = async () => {
        const res = await execDownloadLeads();
        fileDownload(res.data, `prfirm-leads-${DateTime.now().toISO({ includeOffset: false })}.csv`);
    }

    const columns = [{
        field: 'sk',
        name: 'Email',
        dataType: 'string'
    }, {
        field: 'handleitCount',
        name: 'HandleIt Count',
        dataType: 'number',
        width: "200px"
    }, {
        field: 'paidCount',
        name: 'Press Release Count',
        dataType: 'number',
        width: "200px"
    }, {
        field: 'brands',
        name: 'Engaged Brands',
        width: "200px",
        align: "right",
        render: (brands) => {
            return <span>{brands.length}</span>
        },
    }]

    return (
        <Fragment>
            <EuiPanel>
                <EuiGlobalToastList
                    toasts={toasts}
                    dismissToast={removeToast}
                    toastLifeTimeMs={3000}
                />
                <EuiTitle size="m">
                    <h1>Leads</h1>
                </EuiTitle>
                <EuiSpacer />
                <EuiButton isLoading={downloadingLeads} onClick={() => downLoadLeads()} iconType="download" size='s' >Download</EuiButton>
                <EuiSpacer />
                <EuiBasicTable
                    loading={loading}
                    items={data?.leads || []}
                    columns={columns}
                    noItemsMessage="There are no leads available"
                    error={error != null ? 'Error loading leads' : null}
                >
                </EuiBasicTable>
            </EuiPanel>
        </Fragment>
    );
}