import { Fragment, useState } from 'react';
import {
    htmlIdGenerator, EuiGlobalToastList, EuiPanel, EuiSwitch, EuiLink, EuiBasicTable, EuiTitle, EuiSpacer,
    EuiFlexGroup, EuiFlexItem, EuiButton, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, EuiButtonEmpty,
    EuiModalBody, EuiForm, EuiFormRow, EuiFieldText
} from '@elastic/eui';
import useAxios from 'axios-hooks'
import { useUser } from '@clerk/clerk-react';
const genToastId = htmlIdGenerator('toast');
export default function Brands(props) {
    const userApi = useUser();
    const [toasts, setToasts] = useState([]);

    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };

    const [
        { loading: updatingBrand },
        executeUpdateBrand
    ] = useAxios(
        {
            url: '/data/brands/update-features',
            method: 'POST'
        },
        { manual: true }
    )

    function updateBrandFeature(brandId, feature, value) {
        executeUpdateBrand({
            data: {
                accountId: props.account.accountId,
                brandId: brandId,
                feature: feature,
                value: value
            }
        })
            .then(() => {
                addToast({ title: "Brand features updated!", color: 'success' })
                props.refetchAccount()
            })
            .catch((e) => {
                addToast({ title: "Error updating brand features", color: 'danger' })
            })
            .finally(() => {
                setNewBrandName('')
                closeModal()
            })
    }

    const columns = [
        {
            field: 'brandName',
            name: 'Brand'
        },
        {
            field: 'featureLeads',
            name: 'Feature | Leads',
            width: "50px",
            align: "center",
            render: (featureLeads, brand) => {
                return <EuiSwitch onChange={(e) => { updateBrandFeature(brand.brandId, "featureLeads", e.target.checked) }} checked={featureLeads} showLabel={false} label="Leads Feature"></EuiSwitch>
            }
        },
        {
            field: 'featureDiscountCodes',
            name: 'Feature | Discount Codes',
            width: "50px",
            align: "center",
            render: (featureDiscountCodes, brand) => {
                return <EuiSwitch onChange={(e) => { updateBrandFeature(brand.brandId, "featureDiscountCodes", e.target.checked) }} checked={featureDiscountCodes} showLabel={false} label="Discount Codes Feature"></EuiSwitch>
            }
        },
        {
            field: 'nurtureActive',
            name: 'Nurture Active',
            width: "50px",
            align: "center",
            render: (nurtureActive) => nurtureActive ? 'Yes' : 'No'
        },
        {
            field: 'editorCount',
            name: 'Editor Count',
            dataType: 'number',
            width: "50px",
            align: "center",
            render: (editorCount) => editorCount || 0
        },
        {
            field: 'salesCount',
            name: 'Sales Count',
            dataType: 'number',
            width: "50px",
            align: "center",
            render: (salesCount) => salesCount || 0
        },
        {
            field: 'prFeedUrl',
            name: 'Press Release Site',
            width: "100px",
            align: "center",
            render: (prFeedUrl, item) => {
                if (prFeedUrl) return <EuiLink target="_blank" href={prFeedUrl} >View</EuiLink>
                else return "Not Available"
            },
        },
        {
            field: 'ecommUrl',
            name: 'eCommerce Site',
            width: "100px",
            align: "center",
            render: (ecommUrl, item) => {
                if (ecommUrl) return <EuiLink target="_blank" href={ecommUrl} >View</EuiLink>
                else return "Not Available"
            },
        }
    ];


    const [newBrandName, setNewBrandName] = useState('');
    const onNewBrandNameChanged = (e) => setNewBrandName(e.target.value);

    const [
        { loading: creatingBrand },
        executePut
    ] = useAxios(
        {
            url: '/data/brands',
            method: 'POST'
        },
        { manual: true }
    )

    function addBrand() {
        executePut({
            data: {
                accountId: props.account.accountId,
                name: newBrandName
            }
        })
            .then(() => {
                addToast({ title: "Brand created!", color: 'success' })
                props.refetchAccount()
            })
            .catch((e) => {
                addToast({ title: "Error creating brand", color: 'danger' })
            })
            .finally(() => {
                setNewBrandName('')
                closeModal()
            })
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);

    return (
        <Fragment>
            <EuiPanel paddingSize="l" grow={true} style={{ paddingBottom: "75px" }}>
                <EuiGlobalToastList
                    toasts={toasts}
                    dismissToast={removeToast}
                    toastLifeTimeMs={3000}
                />
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiTitle size="m">
                            <h1>Brands</h1>
                        </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        {(userApi?.user?.publicMetadata?.platformAdmin === 'yes' || props.account?.isAccountAdmin) &&
                            <EuiButton disabled={creatingBrand || isModalVisible} fill onClick={showModal}>Add Brand</EuiButton>
                        }
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />
                <EuiBasicTable
                    loading={updatingBrand || creatingBrand}
                    tableCaption="List of account brands"
                    items={props.account?.brands || []}
                    rowHeader="sk"
                    columns={columns}
                    tableLayout="auto"
                />
                {isModalVisible &&
                    <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
                        <EuiModalHeader>
                            <EuiModalHeaderTitle>
                                <h1>Create New Brand</h1>
                            </EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            <EuiForm component="form" disabled={creatingBrand}>
                                <EuiFormRow label="Brand Name">
                                    <EuiFieldText value={newBrandName} name="popfirst" onChange={(e) => onNewBrandNameChanged(e)} />
                                </EuiFormRow>
                            </EuiForm>
                        </EuiModalBody>

                        <EuiModalFooter>
                            <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
                            <EuiButton isLoading={creatingBrand} onClick={addBrand} fill>
                                Save
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>}
            </EuiPanel>
        </Fragment>
    );
}