import {
    EuiButton, EuiButtonIcon, EuiFlexGroup,
    EuiFlexItem, EuiFormRow, EuiPage, EuiPageBody,
    EuiPanel, EuiSpacer, EuiText,
    EuiGlobalToastList,
    htmlIdGenerator,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiBadge,
    EuiComboBox,
    EuiHorizontalRule,
    EuiSelect
} from '@elastic/eui';
import { Fragment, useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import useAxios from "axios-hooks";
import { DateTime } from "luxon";
export default function Promote(props) {
    const today = DateTime.now();
    const weekOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => {
        const ts = today.minus({ days: 7 * i });
        const weekYear = ts.weekYear
        const weekNumber = ts.weekNumber
        const week = DateTime.fromObject({ weekYear, weekNumber, weekDay: 1 })
        const pk = ts.toFormat('kkkk|WW');
        return {
            value: pk,
            text: `Week Starting ${week.toISODate()}`
        }
    })
    const [selectedWeek, setSelectedWeek] = useState('');

    const [{ data: accountDetails }] = useAxios({
        method: 'get',
        url: '/data/accounts',
        params: {
            accountId: props.account.accountId
        },
    });

    const [
        { data: sendPromotionsResult, loading: sendindPromotions },
        executeSendPromotions
    ] = useAxios(
        {
            url: '/data/promotions',
            method: 'POST'
        },
        { manual: true }
    )

    const [
        { loading: downloadingHandleIts },
        executeDownload
    ] = useAxios(
        {
            url: '/data/handleit-download',
            method: 'POST',
            responseType: 'blob'
        },
        { manual: true }
    )

    const onDownload = function () {
        executeDownload({
            data: {
                weekYear: selectedWeek.split('|')[0],
                weekNumber: selectedWeek.split('|')[1],
                accountId: props.account.accountId
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${props.account.accountId}-${selectedWeek.split('|').join('-')}.csv`);
                document.body.appendChild(link);
                link.click();
            });

    }

    const onSendPromotions = function () {
        const promotionsToSend = [];
        promotions.forEach(p => {
            const fromEmail = p.selectedUserOptions[0].value.email;
            p.selectedBrandOptions.forEach(b => {
                promotionsToSend.push({
                    toEmails: p.promotionRecipientOptions.map(o => o.value),
                    brandId: b.value,
                    fromEmail
                })
            })
        })
        executeSendPromotions({ data: { accountId: props.account.accountId, promotions: promotionsToSend } })
            .then(() => {
                addToast({ title: "Promotions Sent!", color: 'success' })
                showModal();
                setPromotions([])
            })
            .catch((e) => {
                addToast({ title: "Error sending promotions", color: 'danger' })
            })
            .finally(() => {
            });
    }

    const [promotions, setPromotions] = useState([]);
    const onAddPromotion = function () {
        const curPromotions = [...promotions];
        curPromotions.push({ promotionRecipientOptions: [] });
        setPromotions(curPromotions);
    }
    const onPromotionUserChanged = function (indx, selectedUserOptions) {
        const curPromotions = [...promotions];
        if (selectedUserOptions[0]) {
            curPromotions[indx].selectedUserOptions = selectedUserOptions;
            const userBrandIds = selectedUserOptions[0].value.brands
            curPromotions[indx].selectedBrandOptions = props?.account?.brands?.filter(b => userBrandIds.indexOf(b.brandId) !== -1).map(b => { return { value: b.brandId, label: b.brandName } });
            setPromotions(curPromotions);
        } else {
            curPromotions[indx].selectedUserOptions = []
            curPromotions[indx].selectedBrandOptions = [];
            setPromotions(curPromotions);
        }
    }
    const onCreateUserOption = (i, searchValue) => {
        const normalizedSearchValue = searchValue.trim().toLowerCase();

        if (!normalizedSearchValue) {
            return;
        }

        const newOption = {
            label: searchValue,
            value: { email: searchValue, brands: [] }
        };

        // Select the option.
        onPromotionUserChanged(i, [newOption]);
    };
    const onPromotionBrandChanged = function (indx, selectedOptions) {
        const curPromotions = [...promotions];
        curPromotions[indx].selectedBrandOptions = selectedOptions;
        setPromotions(curPromotions);
    }

    const onCreatePromotionRecipientOption = (i, searchValue) => {
        const normalizedSearchValue = searchValue.trim().toLowerCase();

        if (!normalizedSearchValue) {
            return;
        }

        const newOption = {
            label: searchValue,
            value: searchValue
        };

        // Select the option.
        onPromotionRecipientsChange(i, [...promotions[i].promotionRecipientOptions, newOption]);
    };
    const onPromotionRecipientsChange = function (indx, selectedOptions) {
        const curPromotions = [...promotions];
        curPromotions[indx].promotionRecipientOptions = selectedOptions;
        console.log(`Index ${indx} | ${JSON.stringify(curPromotions[indx].promotionRecipientOptions)}`)
        setPromotions(curPromotions);
    }

    const removePromotion = function (i) {
        const curPromotions = [...promotions];
        curPromotions.splice(i, 1);
        setPromotions(curPromotions);
    }


    const [toasts, setToasts] = useState([]);
    const genToastId = htmlIdGenerator('toast');
    const addToast = (toast) => {
        toast.id = genToastId();
        setToasts(toasts.concat(toast));
    };
    const removeToast = removedToast => {
        setToasts(toasts.filter(toast => toast.id !== removedToast.id));
    };


    const [isModalVisible, setIsModalVisible] = useState(false);
    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);
    let modal;
    if (isModalVisible) {
        modal = (
            <EuiModal onClose={closeModal}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Promotions Results</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    {sendPromotionsResult.promotions.map(p => {
                        let color, label
                        if (p.success === true) { color = 'success'; label = 'Success'; }
                        else { color = 'danger'; label = 'Error'; }
                        if (p.status === 'excludedRole') { label += ' | Excluded Role'; }
                        if (p.status === 'excludedDomain') { label += ' | Excluded Domain'; }
                        return (
                            <EuiFlexGroup alignItems='center'>
                                <EuiFlexItem grow={false}>
                                    <EuiBadge style={{ textAlign: "center" }} color={color}>{label}</EuiBadge>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiText>{p.toEmail || 'Unknown Email'} | {p.brandName || 'Unknown Brand'}</EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        )
                    })}
                </EuiModalBody>
            </EuiModal>
        );
    }

    return (
        <Fragment>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={3000}
            />
            {props?.account && accountDetails &&
                <EuiPage paddingSize="none" className='full-height'>
                    <EuiPageBody >
                        <EuiPanel paddingSize="l" grow={true} style={{ paddingBottom: "75px" }}>
                            <Routes>
                                <Route path='promote/*' element={
                                    <Fragment>
                                        <EuiText><h2>{`Promote ${props?.account?.accountName}`}</h2></EuiText>
                                        <EuiSelect
                                            hasNoInitialSelection={true}
                                            placeholder="Select HandleIt Week"
                                            options={weekOptions}
                                            value={selectedWeek}
                                            onChange={(e) => { setSelectedWeek(e.target.value) }}
                                        />
                                        <EuiButton isLoading={downloadingHandleIts} onClick={() => { onDownload() }}>Download</EuiButton>
                                        <EuiSpacer />
                                        {promotions && promotions.map((p, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <EuiFlexGroup key={`indx-${i}`} wrap>
                                                        <EuiFlexItem grow={false} style={{ width: 450 }}>
                                                            <EuiFormRow label="Forwarded From" fullWidth>
                                                                <EuiComboBox fullWidth
                                                                    onCreateOption={(val) => onCreateUserOption(i, val)}
                                                                    singleSelection={{ asPlainText: true }}
                                                                    placeholder="Select a user"
                                                                    selectedOptions={promotions[i]?.selectedUserOptions || []}
                                                                    options={accountDetails?.item?.userDetails?.filter(u => u.brands && u.brands.length > 0).map(b => { return { value: b, label: b.email } }) || []}
                                                                    onChange={(selected) => { onPromotionUserChanged(i, selected) }}
                                                                />
                                                            </EuiFormRow>
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false} style={{ width: 350 }}>
                                                            <EuiFormRow label="Brands" fullWidth>
                                                                <EuiComboBox fullWidth
                                                                    isClearable={true}
                                                                    placeholder="Select brands"
                                                                    selectedOptions={promotions[i]?.selectedBrandOptions || []}
                                                                    options={props?.account?.brands?.map(b => { return { value: b.brandId, label: b.brandName } }) || []}
                                                                    onChange={(selectedOptions) => { onPromotionBrandChanged(i, selectedOptions) }}
                                                                />
                                                            </EuiFormRow>
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false} style={{ width: 350 }}>
                                                            <EuiFormRow label="Promotion Recipients" fullWidth>
                                                                <EuiComboBox fullWidth
                                                                    onCreateOption={(val) => onCreatePromotionRecipientOption(i, val)}

                                                                    placeholder="Add recipients"
                                                                    selectedOptions={promotions[i]?.promotionRecipientOptions || []}
                                                                    options={[]}
                                                                    onChange={(selected) => { onPromotionRecipientsChange(i, selected) }}
                                                                />
                                                            </EuiFormRow>
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false}>
                                                            <EuiFormRow hasEmptyLabelSpace>
                                                                <EuiButtonIcon
                                                                    display="base"
                                                                    iconType="trash"
                                                                    aria-label="Delete"
                                                                    color="danger"
                                                                    onClick={() => removePromotion(i)}
                                                                />
                                                            </EuiFormRow>
                                                        </EuiFlexItem>
                                                    </EuiFlexGroup>
                                                    <EuiHorizontalRule />
                                                </Fragment>
                                            )
                                        })
                                        }
                                        <EuiSpacer />
                                        <EuiFlexGroup>
                                            <EuiFlexItem grow={false}>
                                                <EuiButton onClick={() => { onAddPromotion() }}>Add Promotion</EuiButton> </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                <EuiButton isLoading={sendindPromotions} disabled={promotions.length === 0} fill onClick={() => { onSendPromotions() }}>Send Promotions</EuiButton>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </Fragment>
                                } />
                                <Route path="*" element={<Navigate to="promote" />} />
                            </Routes>
                        </EuiPanel>
                    </EuiPageBody>
                </EuiPage>
            }
            {modal}
        </Fragment>
    );
}